import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Vinh Nguyen | Full-stack Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Vinh Nguyen',
  subtitle: "full-stack engineer",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'With over ten years of working in web development, infrastructure, data platform; experiences ranging from system architecture, back-end, front-end development and offshore team management.',
  paragraphTwo: 'Programming language and framework: Ruby, Javascript, Python, Rails, Django, React, Mysql, Postgresql, Linux, GCP, AWS.',
  paragraphThree: 'Language: Vietnamese, Japanese and English.',
//  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'veeva_ej.png',
    title: 'VEEVA Engagement Journey',
    info: 'An application that gathers data from various sources using collaborative filtering to recommend optimal next actions for Medical Representatives (MR) when communicating with healthcare professionals',
    info2: 'Position: engineer',
  },
  {
    id: nanoid(),
    img: 'jupiter.png',
    title: 'LINE Chatbot Platform',
    info: 'Easy-to-use AI-powered chatbot platform enabling users to define bot behavior with natural language (no coding required). Technology stack: Python, Django, FastAPI, React, DynamoDB, ECS Fargate',
    info2: 'Position: engineer',
  },
  {
    id: nanoid(),
    img: 'moneytree.png',
    title: 'Moneytree Data Platform',
    info: 'Moneytree data platform is the core system of Moneytree where we aggregate data from various sources such as Bank’s API, scraping. Other products of Moneytree are built around this platform.',
    info2: 'Position: back-end and infra engineer',
    url: 'https://getmoneytree.com/jp/app/about',
  },
  {
    id: nanoid(),
    img: 'smocca.png',
    title: 'smocca.jp',
    info: 'Smocca.jp is ZIGExN’s estate service in Japan. It allows user to search for apartment, rent house in Japan. Its technology stack consists Ruby on Rails, MySQL for data store, Solr for full-text search, Apache and Passenger for web application, Nginx, LVS for load balancing and Chef for server orchestration.',
    info2: 'Position: engineer, technical leader',
    url: 'https://smocca.jp',
  },
  {
    id: nanoid(),
    img: 'arubaito.png',
    title: 'arubaito.jp',
    info: 'Arubaito is ZIGExN’s recruitment consulting service in Japan. It specializes in part-time, seasoned recruitment.',
    info2: 'Position: Engineer',
    url: 'https://arubaito-ex.jp',
  },
  {
    id: nanoid(),
    img: 'kuruma.png',
    title: 'kuruma-ex.jp',
    info: 'Kuruma-ex is a used car trading service. User can search for cars of various maker, model and bought it on the platform.',
    info2: 'Position: Technical leader',
    url: 'https://kuruma-ex.jp',
  },
  {
    id: nanoid(),
    img: 'hotelista.png',
    title: 'Hotelista',
    info: 'Hotelista.jp is an old service of AppleWorld, providing hotel searching service (B2B and B2C)',
    info2: 'Position: infra engineer',
    url: 'https://hotelista.jp',
  },
  {
    id: nanoid(),
    img: 'travel-entry.png',
    title: 'travel-entry',
    info: 'travel-entry.com is a meta-search application for hotels, user can search and compare the price of hotels from all suppliers such as Expedia, Booking.com, Rakuten travel…',
    info2: 'Position: full-stack engineer',
    url: 'https://travel-entry.com/ja',
  }
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'xuanvinh@live.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/vinh-nguyen-bba06b65/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/zinh',
    },
    {
      id: nanoid(),
      name: 'rss',
      url: 'https://blog.monotone.dev'
    }
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
